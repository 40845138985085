import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Button from "../Button";
import { mobileSizeQuery, minTabletSizeQuery } from "../../theme/common";

const commentCommon = css`
  position: relative;
  width: fit-content;
  background-color: white;
  padding: 12px 16px;
  padding-right: 30px;
  border-radius: 10px;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 16px;
  }
`;

export const SceneComponentRoot = styled.div<{ mobHeight: number }>`
  position: relative;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 220px;
  padding-top: 16px;
  user-select: none;

  @media (${minTabletSizeQuery}) {
    height: unset;
    min-height: ${({ mobHeight }) => mobHeight}px;
    padding: 84px 16px 78px;
    overflow: auto;
  }
`;

export const DescButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;

  @media (${mobileSizeQuery}) {
    z-index: 2;
  }
`;

export const SceneComponentImgContainer = styled.div`
  position: relative;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  filter: ${(props) =>
    `drop-shadow(5pt 5pt 1.5pt ${props.theme.color.shadow})`};

  .copyright {
    position: absolute;
    bottom: 5px;
    right: 12px;
    white-space: nowrap;
    color: ${(props) => props.theme.color.grey};
    font-size: 10px;
  }
`;

export const SceneComponentImg = styled.img`
  max-height: calc(100vh - 310px);
  max-width: 100%;
  height: unset;
  width: unset;
  border: 8px solid white;
  border-radius: 3px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;
  border-bottom: 27px solid white;

  @media (${mobileSizeQuery}) {
    max-height: 350px;
  }
`;

export const SceneComponentComments = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (${mobileSizeQuery}) {
    width: 100%;
  }
`;

export const SceneComponentCommentRight = styled.div`
  ${commentCommon}
  align-self: flex-end;

  &::after {
    right: -12px;
    border-right: 7px solid transparent;
    border-left: 7px solid white;
    border-bottom: 6px solid white;
    border-top: 6px solid transparent;
  }
`;

export const SceneComponentCommentLeft = styled.div`
  ${commentCommon}
  align-self: flex-start;
  padding-right: 50px;

  &::after {
    left: -12px;
    border-left: 7px solid transparent;
    border-right: 7px solid white;
    border-bottom: 6px solid white;
    border-top: 6px solid transparent;
  }

  &.expandable {
    padding-right: 16px;
  }

  &.expanded {
    width: 100%;
  }

  .comment-header {
    color: ${(props) => props.theme.color.black};
    font-weight: bold;
  }

  .expanded-text {
    margin-top: 4px;
    color: ${(props) => props.theme.color.black};
  }
`;

export const SceneComponentCommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.color.lightBlue};
`;

export const MagnifyImage = styled.img`
  margin-left: 40px;
  margin-right: auto;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    margin-left: 15px;
  }
`;

export const ExpandMagnifyImage = styled.img`
  position: absolute;
  bottom: 4px;
  left: 12px;
`;

export const SceneComponentCommentFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export const MessageIcon = styled.img`
  width: 32px;
  margin-right: 35px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    margin-right: 10px;
  }
`;

export const Detective = styled.img`
  position: absolute;
  width: 14%;
  left: 38px;
  bottom: 43px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;
`;
