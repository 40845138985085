import { css } from "@emotion/react";
import { MOBILE_SIZE, MIN_TABLET_SIZE, MAX_TABLET_SIZE } from "../lib/constants";

export const mobileSizeQuery = `max-width: ${MOBILE_SIZE}px`;
export const maxMobileSizeQuery = `min-width: ${MOBILE_SIZE}px`
export const minTabletSizeQuery = `max-width: ${MIN_TABLET_SIZE}px`;
export const maxTabletSizeQuery = `min-width: ${MAX_TABLET_SIZE}px`;

export const outerShadow = css`
  box-shadow: -5px -5px 15px #ffffff80, 5px 5px 20px #0000001a;
`;

export const bubbleShadow = css`
  filter: drop-shadow(5px 5px 3px #0000001a)
    drop-shadow(-5px -5px 3px #ffffff33);
`;

export const buttonOuterShadow = css`
  box-shadow: 5px 5px 10px #00000066, -5px -5px 10px #ffffffff;
`;

export const buttonInsetOuterShadow = css`
  box-shadow: inset 5px 5px 10px #00000066, inset -5px -5px 10px #ffffffff;
`;
