import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ArtworkDescription from "../ArtworkDescription";
import DescriptionTabs from "../DescriptionTabs";
import ModalPicture from "../ModalPicture";
import { Typography } from "../Typography";
import MagnifyImg from "../../icons/magnify.svg";
import {
  SceneInfoRoot,
  SceneInfoImg,
  SceneInfoImgContainer,
  SceneInfoLeft,
  BackButton,
  ExpandMagnifyImage,
} from "./SceneInfo.style";
import { pushDataLayer } from '../../lib/analytics';

const SceneInfo = (props) => {
  const { scene, goToPage } = props;
  const [isPictureModalOpen, setIsPictureModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as null | { startWizardPage: number };

  const onBack = () => {
    if (locationState?.startWizardPage === 4) {
      navigate(-1);
    } else {
      goToPage(3);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SceneInfoRoot>
      <BackButton
        iconSide="left"
        iconName="left-arrow"
        onClick={() => onBack()}
      >
        もどる
      </BackButton>
      <SceneInfoLeft>
        <SceneInfoImgContainer
            onClick={() => {
              setIsPictureModalOpen(true)
              pushDataLayer({event:'image_expand_from_sceneInfo', label: scene.orderStory.toString() });
            }}
          >
          <SceneInfoImg
            src={scene.picture && scene.picture.url}
            className={scene.copyrights && "with-copyright"}
          />
          <ExpandMagnifyImage src={MagnifyImg} alt="magnify" />
          {scene.copyrights && (
            <Typography variant="copyright" className="copyright">
              {scene.copyrights}
            </Typography>
          )}
        </SceneInfoImgContainer>
        <ArtworkDescription
          name={scene.title}
          author={scene.author}
          size={scene.size}
          type={scene.techniqueMaterial}
          year={scene.productionYear}
        />
      </SceneInfoLeft>
      <DescriptionTabs
        description={scene.seeMore}
        fullDescription={scene.learnMore}
        trivia={scene.trivia}
      />
      <ModalPicture
        isOpen={isPictureModalOpen}
        closeModal={() => setIsPictureModalOpen(false)}
        src={scene.picture && scene.picture.url}
        copyright={scene.copyrights}
      />
    </SceneInfoRoot>
  );
};

export default SceneInfo;
