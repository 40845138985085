import { useRef, useState } from "react";

import { useScreenSize } from "../../hooks/useScreenSize";
import {
  HomeLayoutRoot,
  HomeBodyContainer,
  HomeTitleContainerDiv,
  LogoImage,
  Floor,
  HomeFooter,
} from "./Home.style";

import HomeGallery from "../../components/HomeGallery";
import Header from "../../components/Header";
import CopyrightFooter from "../../components/CopyrightFooter";
import SiteCloseMessage from "../../components/SiteCloseMessage";

import LogoImg from "../../icons/logo.svg";

const Home = () => {
  // const navigate = useNavigate();
  const { isMobileMode } = useScreenSize();


  return (
    <div>
      {isMobileMode && <Header />}
      <HomeLayoutRoot>
        <HomeBodyContainer>
          <SiteCloseMessage />

          <HomeTitleContainerDiv>
            <LogoImage src={LogoImg} alt="logo" />
          </HomeTitleContainerDiv>
          <HomeGallery />
        </HomeBodyContainer>
        <HomeFooter>
          <CopyrightFooter />
          <Floor />
        </HomeFooter>
      </HomeLayoutRoot>
    </div>
  );
};

export default Home;
