import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { mobileSizeQuery } from "../../theme/common";

import Button from "../../components/Button";
import { Typography } from "../../components/Typography";

export const CopyrightRoot = styled.div<{ widthPer: number }>`
  width: ${({ widthPer }) => widthPer}%;
`;

export const columnCommon = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 60px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ScenesRoot = styled.div<{ mobHeight: number }>`
  overflow: auto;
  min-height: calc(100vh - 48px);
  user-select: none;

  @media (${mobileSizeQuery}) {
    min-height: ${({ mobHeight }) => mobHeight}px;
  }
`;

export const ScenesContainer = styled.div`
  display: flex;
  padding: 82px;
  padding-bottom: 52px;

  @media (${mobileSizeQuery}) {
    flex-direction: column;
    padding: 16px;
    padding-top: 90px;
  }
`;

export const ScenesMobileRow = styled.div`
  display: flex;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SceneneMobileColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  &.column1 {
    width: 45%;
  }

  &.column2 {
    width: 55%;
  }
`;

export const BackButton = styled(Button)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

export const ScenesImage = styled.img`
  width: 100%;
  height: auto;
  border: 8px solid white;
  border-radius: 3px;
  box-shadow: ${(props) => `5pt 5pt 3.5pt ${props.theme.color.shadow}`};
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;
`;

export const SceneImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (${mobileSizeQuery}) {
    margin-bottom: 32px;
  }
`;

export const ScenesColumn1 = styled.div`
  ${columnCommon}
  width: 18%;
`;

export const ScenesColumn2 = styled.div`
  ${columnCommon}
  width: 29%;
`;

export const ScenesColumn3 = styled.div`
  ${columnCommon}
  width: 31%;
`;

export const ScenesColumn4 = styled.div`
  ${columnCommon}
  width: 22%;
`;
