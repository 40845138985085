import styled from "@emotion/styled";

import { mobileSizeQuery } from "../../theme/common";

export const SceneQuestionRoot = styled.div<{ mobHeight: number }>`
  height: calc(100vh - 48px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  user-select: none;

  .question {
    width: 115%;
  }

  &.fade-transition-exit .question {
    transform: translateY(60px);
    transition: transform 600ms;
  }

  &.fade-transition-exit-active .question {
    transform: translateY(60px);
  }

  @media (max-width: 1100px) {
    padding: 50px 40px;
  }

  @media (max-width: 720px) {
    padding: 50px 55px;
    flex-direction: column-reverse;

    .question {
      min-height: 212px;
    }

    img {
      width: 180px;
      margin-bottom: 40px;
    }
  }

  @media (${mobileSizeQuery}) {
    height: ${({ mobHeight }) => mobHeight - 48}px;

    .question {
      min-height: 108px;
    }
  }
`;

export const Detective = styled.img`
  width: 160px;
  margin: 0 120px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

    &.detective-activated {
      animation: poyoyon 1s ease-in-out 1 forwards;
    }

    @keyframes poyoyon {
      0%  {
        transform: scale(1.0, 1.0) translate(0, 0);
      }
      15% {
        transform: scale(0.98, 0.9) translate(0, 5px);
      }
      30% {
        transform: scale(1.02, 1.0) translate(0, 8px);
      }
      50% {transform: scale(0.98, 1.05) translate(0, -8px);
      }
      70% {
        transform: scale(1.0, 0.9) translate(0, 5px);
      }
      100% {
        transform: scale(1.0, 1.0) translate(0, 0);
      }
      0%, 100% {
        opacity: 1;
      }
  }

  @media (max-width: 720px) {
    margin: 30px 0 0;
  }
`;
