import { FC, useMemo, CSSProperties } from "react";
import { Variants } from "./typographyConfig";
import { create } from "./Typography.style";
import clsx from "clsx";

interface Props {
  variant: Variants;
  className?: string;
  styles?: CSSProperties;
  white?: boolean;
  center?: boolean;
  withLine?: boolean;
}

export const Typography: FC<Props> = (props) => {
  const { variant, className, children, styles, white, center, withLine } =
    props;

  const Component = useMemo(() => create(variant), [variant]);

  return (
    <Component
      white={white}
      center={center}
      withLine={withLine}
      className={clsx(className)}
      style={styles}
    >
      {children}
    </Component>
  );
};
