import { FC } from "react";
import { NextButton, BackButton } from "./Navigation.style";

type Props = {
  onBack: () => void;
  onNext: () => void;
  activePageIndex: number;
};

const Navigation: FC<Props> = ({ onBack, onNext, activePageIndex }) => (
  <>
    <BackButton 
      iconSide="left" 
      iconName="left-arrow" 
      onClick={() => onBack()}
      className={activePageIndex === 0 ? "withHeader" : ""} 
    >
      もどる
    </BackButton>

    <NextButton
      dark
      iconName="right-arrow"
      onClick={() => onNext()}
      className={activePageIndex === 2 ? "absolute" : ""}
    >
      すすむ
    </NextButton>
  </>
);
export default Navigation;
