import styled from "@emotion/styled";

import Button from "../../components/Button";
import { mobileSizeQuery } from "../../theme/common";

export const ErrorContainer = styled.div<{ mobHeight: number }>`
  display: flex;
  height: calc(100vh - 48px);
  overflow: hidden;
  user-select: none;

  @media (${mobileSizeQuery}) {
    flex-direction: column-reverse;
    justify-content: space-between;
    height: ${({ mobHeight }) => mobHeight}px;
    padding: 40% 40px 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: calc(50% - 20px);
  padding: 0 65px 0 85px;

  @media (${mobileSizeQuery}) {
    width: 100%;
    padding: 0;
  }
`;

export const Image = styled.img`
  position: relative;
  bottom: -10px;
  align-self: flex-end;
  width: 100%;
  max-width: 500px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 50%;
  }
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 8%;
  width: calc(50% + 20px);

  @media (${mobileSizeQuery}) {
    width: 100%;
  }
`;

export const BackButton = styled(Button)`
  margin-top: 34px;

  @media (${mobileSizeQuery}) {
    margin-top: 20px;
  }
`;
