import { useNavigate, useParams } from "react-router-dom";
import { useAppStore } from "../../store";
import { Scene } from "../../lib/scene";
import { useScreenSize } from "../../hooks/useScreenSize";
import { Typography } from "../../components/Typography";

import {
  ScenesRoot,
  ScenesContainer,
  ScenesImage,
  SceneImgContainer,
  ScenesMobileRow,
  SceneneMobileColumn,
  ScenesColumn1,
  ScenesColumn2,
  ScenesColumn3,
  ScenesColumn4,
  BackButton,
  CopyrightRoot,
} from "./Scenes.style";

const initialTabletColumns: Record<string, Scene[]> = {
  column1: [],
  column2: [],
  column3: [],
  column4: [],
};

type MobileColumn = {
  name: string;
  data: Scene[];
};

type MobileRow = Record<number, MobileColumn>;

const Scenes = () => {
  const { storyName } = useParams();
  const navigate = useNavigate();
  const { isMobileMode, innerHeight } = useScreenSize();
  const story = useAppStore((state) => state.stories[storyName as string]);

  const navigateToScene = (artwork) =>
    navigate(`/${storyName}/scene/${artwork.orderStory}`, {
      state: { startWizardPage: 4 },
    });

  const TabletLayout = () => {
    const sortedArtworks = story
      ? [...story.artworks].sort(
          (a, b) => a.menuLayout.order - b.menuLayout.order
        )
      : [];

    const columns = sortedArtworks.reduce((acc, curr) => {
      const columnName = `column${curr.menuLayout.column}`;
      return {
        ...acc,
        [columnName]: [...acc[columnName], curr],
      };
    }, initialTabletColumns);

    return (
      <>
        <ScenesColumn1>
          {columns.column1.map((artwork) => (
            <SceneImgContainer>
              <ScenesImage
                alt={artwork.title}
                src={artwork.thumbnailPicture.url}
                style={{ width: artwork.menuLayout.width }}
                onClick={() => navigateToScene(artwork)}
              />
              {artwork.copyrights &&
                <CopyrightRoot widthPer={parseInt(artwork.menuLayout.width)}>
                  <Typography variant="copyright" className="copyright" center={true} white={true}>
                    {artwork.copyrights}
                  </Typography>
                </CopyrightRoot>
              }
            </SceneImgContainer>
          ))}
        </ScenesColumn1>
        <ScenesColumn2>
          {columns.column2.map((artwork) => (
            <SceneImgContainer>
              <ScenesImage
                alt={artwork.title}
                src={artwork.thumbnailPicture.url}
                style={{ width: artwork.menuLayout.width }}
                onClick={() => navigateToScene(artwork)}
              />
              {artwork.copyrights &&
                <CopyrightRoot widthPer={parseInt(artwork.menuLayout.width)}>
                  <Typography variant="copyright" className="copyright" center={true} white={true}>
                    {artwork.copyrights}
                  </Typography>
                </CopyrightRoot>
              }
            </SceneImgContainer>
          ))}
        </ScenesColumn2>
        <ScenesColumn3>
          {columns.column3.map((artwork) => (
            <SceneImgContainer>
              <ScenesImage
                alt={artwork.title}
                src={artwork.thumbnailPicture.url}
                style={{ width: artwork.menuLayout.width }}
                onClick={() => navigateToScene(artwork)}
              />
              {artwork.copyrights &&
                <CopyrightRoot widthPer={parseInt(artwork.menuLayout.width)}>
                  <Typography variant="copyright" className="copyright" center={true} white={true}>
                    {artwork.copyrights}
                  </Typography>
                </CopyrightRoot>
              }
            </SceneImgContainer>
          ))}
        </ScenesColumn3>
        <ScenesColumn4>
          {columns.column4.map((artwork) => (
            <SceneImgContainer>
              <ScenesImage
                alt={artwork.title}
                src={artwork.thumbnailPicture.url}
                style={{ width: artwork.menuLayout.width }}
                onClick={() => navigateToScene(artwork)}
              />
              {artwork.copyrights &&
                <CopyrightRoot widthPer={parseInt(artwork.menuLayout.width)}>
                  <Typography variant="copyright" className="copyright" center={true} white={true}>
                    {artwork.copyrights}
                  </Typography>
                </CopyrightRoot>
              }
            </SceneImgContainer>
          ))}
        </ScenesColumn4>
      </>
    );
  };

  const MobileLayout = () => {
    const sortedArtworks = story
      ? [...story.artworks].sort(
          (a, b) => a.menuLayout.mobileOrder - b.menuLayout.mobileOrder
        )
      : [];

    const data = sortedArtworks.reduce((acc, curr) => {
      const row = curr.menuLayout.mobileRow;
      const column = curr.menuLayout.mobileColumn;

      return {
        ...acc,
        [row]: {
          ...acc[row],
          [column]: {
            name: `column${column}`,
            data:
              acc[row] && acc[row][column]
                ? [...acc[row][column].data, curr]
                : [curr],
          },
        },
      };
    }, {} as Record<number, MobileRow>);

    return (
      <>
        {Object.values(data).map((row, i) => (
          <ScenesMobileRow key={i}>
            {Object.values(row).map((column, j) => (
              <SceneneMobileColumn className={column.name} key={j}>
                {column.data.map((artwork) => (
                  <SceneImgContainer>
                    <ScenesImage
                      alt={artwork.title}
                      src={artwork.thumbnailPicture.url}
                      style={{ width: artwork.menuLayout.mobileWidth }}
                      onClick={() => navigateToScene(artwork)}
                      key={artwork.id}
                    />
                    {artwork.copyrights &&
                      <CopyrightRoot widthPer={parseInt(artwork.menuLayout.mobileWidth)}>
                        <Typography variant="copyright" className="copyright" center={true} white={true}>
                          {artwork.copyrights}
                        </Typography>
                      </CopyrightRoot>
                    }
                  </SceneImgContainer>
                ))}
              </SceneneMobileColumn>
            ))}
          </ScenesMobileRow>
        ))}
      </>
    );
  };

  return (
    <ScenesRoot mobHeight={innerHeight}>
      <BackButton
        iconSide="left"
        iconName="left-arrow"
        onClick={() => navigate(-1)}
      >
        もどる
      </BackButton>
      <ScenesContainer>
        {isMobileMode ? <MobileLayout /> : <TabletLayout />}
      </ScenesContainer>
    </ScenesRoot>
  );
};

export default Scenes;
