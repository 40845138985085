import styled from "@emotion/styled";

import { mobileSizeQuery } from "../../theme/common";
import { Typography } from "../../components/Typography";
import Button from "../../components/Button";

export const ThanksLayoutRootDiv = styled.div<{ mobHeight: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100vh - 48px);
  user-select: none;

  @media (${mobileSizeQuery}) {
    height: auto;
    min-height: ${({ mobHeight }) => mobHeight}px;
    padding: 70px 0 10px;
    justify-content: center;
  }
`;

export const ThanksBodyContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 42%;
  margin-bottom: 12px;
  align-self: flex-end;
`;

export const ThanksImg = styled.img`
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 205px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (max-width: 890px) {
    width: 15%;
  }
`;

export const ThanksContentDiv = styled.div`
  width: 58%;
  max-width: 552px;
  padding-right: 10px;

  @media (max-width: 890px) {
    width: 100%;
    max-width: 100%;
    padding: 0 16px;
  }
`;

export const ThanksTitleDiv = styled.div`
  margin-bottom: 25px;

  @media (max-width: 890px) {
    display: flex;
    justify-content: left;
    align-items: flex-end;
  }
`;

export const ThanksTitle = styled(Typography)`
  font-size: 40px;
  letter-spacing: -0.6px;

  @media (max-width: 890px) {
    width: 75%;
    font-size: 35px;
    padding-left: 12px;
  }
`;

export const ThanksDescriptionDiv = styled.div`
  position: relative;
  background: #ffffff;
  margin-bottom: 24px;
  padding: 15px;
  border-radius: 10px;
`;

export const ThanksDescription = styled(Typography)`
  letter-spacing: 0px;
  line-height: 1.8em;
`;

export const ThanksButtonGroupDiv = styled.div`
  display: flex;

  @media (max-width: 550px) {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const ThanksButton = styled(Button)`
  margin-right: 8px;

  @media (max-width: 550px) {
    justify-content: space-between;
    margin: 0;

    &.main-page {
      width: calc(45% - 4px);
    }

    &.all-artworks-page {
      width: calc(55% - 4px);
    }
  }
`;

export const NextButton = styled(Button)`
  margin-left: auto;

  @media (max-width: 550px) {
    justify-content: space-between;
    width: 100%;
    margin: 16px 0 0;
  }
`;

export const BackButton = styled(Button)`
  position: absolute;
  z-index: 100;
  top: 16px;
  left: 16px;
`;

export const Floor = styled.div`
  position: absolute;
  height: 60px;
  bottom: -12px;
  right: 0;
  left: 0;
  background: #f3d1b8;
`;
