import { FC } from 'react'
import { TabRoot, TabContent } from './Tab.style';

interface Props {
  value: number;
  index: number;
  handleActveTab: (index: number) => void;
}

const Tab: FC<Props> = ({ children, index, value, handleActveTab }) => (
  <TabRoot onClick={() => handleActveTab(index)}>
    <TabContent className={index === value ? 'active' : ""}>
      {children}
    </TabContent>
  </TabRoot>
);


export default Tab;
