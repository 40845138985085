import React, { useEffect, useState } from "react";
import { Caret } from "./TypingText.style";

const TypingText = ({ text }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [elements, setElements] = useState<Array<string | JSX.Element>>([]);

  useEffect(() => {
    const words = text.split(/<br ?\/?>/);
    const elements: Array<string | JSX.Element> = [];
    words.forEach((word, index) => {
      if (index > 0) {
        elements.push(<br />);
      }
      elements.push(...word.split(""));
    });

    setElements(elements);

    const timeoutId = setTimeout(() => {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex >= elements.length - 1) {
            setIsTyping(false);
            clearInterval(intervalId);
          }
          return prevIndex + 1;
        });
      }, 100);

      return () => clearInterval(intervalId);
    }, 600);

    return () => clearTimeout(timeoutId);
  }, [text]);

  return (
    <>
      {elements.slice(0, currentIndex).map((element, index) => (
        <React.Fragment key={index}>{element}</React.Fragment>
      ))}
      {isTyping && <Caret />}
    </>
  );
};

export default TypingText;
