import styled from "@emotion/styled";
import { mobileSizeQuery } from "../../theme/common";

export const GeneralLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const MainContent = styled.div<{ mobHeight: number }>`
  position: relative;
  height: calc(100vh - 48px);

  @media (${mobileSizeQuery}) {
    height: ${({ mobHeight }) => mobHeight}px;
  }
`;
