import styled from "@emotion/styled";
import { mobileSizeQuery } from "../../theme/common";

export const HeaderRoot = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 10px 16px;
  background: #ffffff;
  user-select: none;

  .link {
    width: 12%;
    max-width: 180px;
  }

  @media (${mobileSizeQuery}) {
    .link {
      width: 35%;
    }
  }
`;

export const HeaderTitleImg = styled.img`
  width: 100%;
`;

export const HeaderLink = styled.a``;
