import styled from "@emotion/styled";

import { mobileSizeQuery } from "../../theme/common";

export const SceneZoomRoot = styled.div<{ mobHeight: number }>`
  position: relative;
  height: calc(100vh - 48px);
  overflow: hidden;

  @media (${mobileSizeQuery}) {
    height: ${({ mobHeight }) => mobHeight}px;
  }
`;

export const ZoomedImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  transform-origin: 0 0;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;
`;
