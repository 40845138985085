import { useState } from "react";

interface DeviceOrientationEventiOS extends DeviceOrientationEvent {
  requestPermission?: () => Promise<"granted" | "denied">;
}

export const maxTilt = 15;

export const useAccelerometer = () => {
  const [accelerometer, setAccelerometer] = useState({ x: 1, y: 1 });
  const [isAccelerometer, setIsAccelerometer] = useState(false);

  const getAccelerometerPermissions = async () => {
    const requestPermission = (
      DeviceOrientationEvent as unknown as DeviceOrientationEventiOS
    ).requestPermission;
    const iOS = typeof requestPermission === "function";

    if (iOS) {
      const response = await requestPermission();
      if (response === "granted") {
        localStorage.setItem("accelerometerPermissions", "true");
      }
    }
  };

  const parseAccelerometerData = (e) => {
    if (!isAccelerometer) setIsAccelerometer(true);
    const { gamma, beta } = e;

    if (gamma && beta) {
      const x = gamma > maxTilt ? maxTilt : gamma < -maxTilt ? -maxTilt : gamma;
      const y = beta > maxTilt ? maxTilt : beta < -maxTilt ? -maxTilt : beta;

      setAccelerometer({ x, y });
    }
  };

  const runAccelerometer = () => {
    window.addEventListener("deviceorientation", parseAccelerometerData, true);
  };

  const stopAccelerometer = () => {
    window.removeEventListener("deviceorientation", parseAccelerometerData);
  };

  return {
    getAccelerometerPermissions,
    runAccelerometer,
    accelerometer,
    stopAccelerometer,
    isAccelerometer,
  };
};
