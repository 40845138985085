import { FC, useEffect } from "react";

import { useScreenSize } from "../../hooks/useScreenSize";
import { useAccelerometer, maxTilt } from "../../hooks/useAccelerometer";

import FirstCharacter from "../../icons/1st_char_01@2x.svg";
import SecondCharacter from "../../icons/2nd_char_01@2x.svg";
import ThirdCharacter from "../../icons/3rd_char_01@2x.svg";
import FourthCharacter from "../../icons/4th_char_01@2x.svg";
import LeftArt from "../../icons/left-art.png";
import CenterArt from "../../icons/center-art.png";
import RightArt from "../../icons/right-art.png";

import {
  HomeGalleryContainer,
  HomeGalleryPictures,
  LeftGalleryPartDiv,
  RightGalleryPartDiv,
  FirstArt,
  SecondArt,
  ThirdArt,
  CharactersDiv,
  ThirdCharacterImg,
  FourthCharacterImg,
  SecondCharacterImg,
  FirstCharacterImg,
} from "./HomeGallery.style";

type Props = {};

const maxTabletShift = 70;
const maxMobileShift = 50;

const tabletShift = maxTabletShift / maxTilt;
const mobileShift = maxMobileShift / maxTilt;

const tabletShiftPictures = tabletShift / 2;
const mobileShiftPictures = mobileShift / 2;

const tabletWidth = 60;
const mobileWidth = 100;

const tabletWidthCoefficient = 5;
const mobileWidthCoefficient = 2;

const HomeGallery: FC<Props> = ({ }) => {
  const {
    runAccelerometer,
    accelerometer,
    stopAccelerometer,
    isAccelerometer,
  } = useAccelerometer();
  const { isMobileMode } = useScreenSize();

  useEffect(() => {
    runAccelerometer();
    return stopAccelerometer();
  }, []);

  const picturesLiveStyles = {
    transform: `translateX(${isMobileMode
      ? accelerometer.x * mobileShiftPictures
      : accelerometer.y * tabletShiftPictures
      }px)`,
  };

  const charactersLiveStyles = {
    transform: `translateX(calc(-50% + ${isMobileMode
      ? accelerometer.x * mobileShift
      : accelerometer.y * tabletShift
      }px))`,
    width: `${isMobileMode
      ? mobileWidth + Math.abs(accelerometer.x / mobileWidthCoefficient)
      : tabletWidth + Math.abs(accelerometer.y / tabletWidthCoefficient)
      }%`,
  };

  return (
    <HomeGalleryContainer>
      <HomeGalleryPictures
        className={!isAccelerometer ? "static" : ""}
        style={isAccelerometer ? picturesLiveStyles : {}}
      >
        <LeftGalleryPartDiv>
          <FirstArt src={LeftArt} />
        </LeftGalleryPartDiv>
        <RightGalleryPartDiv>
          <SecondArt src={CenterArt} />
          <ThirdArt src={RightArt} />
        </RightGalleryPartDiv>
      </HomeGalleryPictures>
      <CharactersDiv
        className={!isAccelerometer ? "static" : ""}
        style={isAccelerometer ? charactersLiveStyles : {}}
      >
        <ThirdCharacterImg
          src={ThirdCharacter}
          alt=""
          className={!isAccelerometer ? "static" : ""}
        />
        <FourthCharacterImg
          src={FourthCharacter}
          alt=""
          className={!isAccelerometer ? "static" : ""}
        />
        <SecondCharacterImg
          src={SecondCharacter}
          alt=""
          className={!isAccelerometer ? "static" : ""}
        />
        <FirstCharacterImg
          src={FirstCharacter}
          alt=""
          className={!isAccelerometer ? "static" : ""}
        />
      </CharactersDiv>
    </HomeGalleryContainer>
  );
};

export default HomeGallery;
