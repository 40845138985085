import styled from "@emotion/styled";
import {
  mobileSizeQuery,
  maxMobileSizeQuery,
  minTabletSizeQuery,
} from "../../theme/common";

import Button from "../../components/Button";

export const HomeLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 48px);
  overflow-x: hidden;
  user-select: none;
`;

export const HomeBodyContainer = styled.div`
  position: relative;
  height: 100%;
  padding-top: 70px;
  padding-bottom: 120px;
`;

export const GalleryButtons = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  justify-content: space-between;

  &.noneclick {
    pointer-events: none;
  }

  @media (${mobileSizeQuery}) {
    top: -43px;
    z-index: 3;
    gap: 8px;
  }
`;

export const GalleryButton = styled(Button)`
  @media (${mobileSizeQuery}) {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Floor = styled.div`
  position: absolute;
  height: 60px;
  top: -48px;
  right: 0;
  left: 0;
  background: #f3d1b8;

  @media (${mobileSizeQuery}) {
    height: 54px;
    z-index: 2;
    top: -29px;
  }
`;

export const HomeFooter = styled.div`
  position: relative;
`;

export const LogoImage = styled.img`
  width: 30%;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 50%;
  }
`;

export const HomeTitleContainerDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const OpenPopupButton = styled(Button)`
  position: absolute;
  left: 65%;
  bottom: calc(70%);

  &.open-popup-button-enter {
    opacity: 0;
    transform: translateY(44px);
  }

  &.open-popup-button-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 600ms, transform 600ms;
  }

  &.open-popup-button-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.open-popup-button-exit-active {
    opacity: 0;
    transform: translateY(44px);
    transition: opacity 600ms, transform 600ms;
  }

  &.noneclick {
    pointer-events: none;
  }

  @media (${mobileSizeQuery}) {
    left: unset;
    bottom: unset;
    right: 5px;
    top: 8px;
  }

  @media (${maxMobileSizeQuery}) and (${minTabletSizeQuery}) {
    bottom: 20%;
    left: 68%;
  }
`;

export const AppearCookieConsentBottun = styled.div`
  position: fixed;
  display: flex;
  bottom: 21px;
  left: 26px;
  width: fit-content;
  z-index: 4;
  align-items: center;
  padding: 10px 10px;
  border: 0;
  border-radius: 20px;
  background: #f3d1b8;
  filter: ${(props) => `drop-shadow(0 3pt 6pt ${props.theme.color.shadow})`};

  &.noneclick {
    pointer-events: none;
  }

  @media (${mobileSizeQuery}) {
    bottom: 15px;
    left: 15px;
  }
`;

export const GearImage = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 999px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;
`;
