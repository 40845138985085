import { FC } from "react";
import Parser from "html-react-parser";
import {
  ArtworkDescriptionRoot,
  ArtworkDescriptionInfo,
} from "./ArtworkDescription.style";
import { Typography } from "../Typography";
import { useScreenSize } from "../../hooks/useScreenSize";

interface Props {
  name: string;
  author: string;
  size: string;
  type: string;
  year: string;
}

const ArtworkDescription: FC<Props> = ({ name, author, size, type, year }) => {
  const { isMobileMode } = useScreenSize();
  const nameVariant = isMobileMode ? "mobileTitle" : "tabletTitle";
  const authorVariant = isMobileMode ? "mobileMain" : "tabletMain";
  const infoVariant = "copyright";

  return (
    <ArtworkDescriptionRoot>
      <Typography className="name" variant={nameVariant}>
        《{Parser(name)}》
      </Typography>
      <Typography className="author" variant={authorVariant}>
        {Parser(author)}
      </Typography>
      <ArtworkDescriptionInfo>
        <Typography variant={infoVariant} className="info" >
          {type}
        </Typography>
        <Typography variant={infoVariant} className="info" >
          {size}
        </Typography>
        <Typography variant={infoVariant} className="info" >
          {year}
        </Typography>
      </ArtworkDescriptionInfo>
    </ArtworkDescriptionRoot>
  );
};

export default ArtworkDescription;
