import { FC, useRef } from "react";

import { Typography } from "../Typography";
import { SiteCloseMessageContainer, SiteCloseMessageTitle, SiteCloseMessageFooter } from "./SiteCloseMessage.style";

import { useScreenSize } from "../../hooks/useScreenSize";

const SiteCloseMessage = () => {
  const nodeRef = useRef(null);
  const { isMobileMode } = useScreenSize();
  const titleVariant = isMobileMode ? "mobileH3" : "tabletH3";
  const textVariant = isMobileMode ? "mobileMain" : "tabletMain";
  const footerVariant = isMobileMode ? "mobileSmall" : "tabletSmall";

  return (
    <SiteCloseMessageContainer ref={nodeRef}>
      <SiteCloseMessageTitle variant={titleVariant}>
        公開終了のお知らせ
      </SiteCloseMessageTitle>
      <Typography variant={textVariant}>

        横浜美術館コレクション鑑賞アプリ「みるみるアート ここはどこ？」は、
        2024年3月31日をもって公開を終了いたしました。

        <br />

        これまで本アプリをご利用いただきましたみなさまに、心より感謝を申し上げます。

      </Typography>

      <SiteCloseMessageFooter variant={footerVariant}>
        開発特別協力 : 野村総合研究所
        <br />
        監修 : 横浜美術館
      </SiteCloseMessageFooter>
    </SiteCloseMessageContainer>
  );
};

export default SiteCloseMessage;
