import React, { ReactNode, forwardRef } from "react";
import clsx from "clsx";

import { ReactComponent as ListIcon } from "../../icons/ionic-ios-list.svg";
import { ReactComponent as RightArrowIcon } from "../../icons/right-arrow.svg";
import { ReactComponent as LeftArrowIcon } from "../../icons/left-arrow.svg";
import { Typography } from "../Typography";
import StyledButton from "./Button.style";

export type Icons = "list" | "right-arrow" | "left-arrow";

type Props = {
  children: ReactNode;
  iconName?: Icons;
  iconSide?: "right" | "left";
  className?: string;
  dark?: boolean;
  withTail?: boolean;
  onClick?(): void;
};
type Ref = HTMLButtonElement | null;

const getIconFromName = (name?: Icons) => {
  switch (name) {
    case "list":
      return <ListIcon className="icon" />;
    case "right-arrow":
      return <RightArrowIcon className="icon right-arrow" />;
    case "left-arrow":
      return <LeftArrowIcon className="icon" />;
    default:
      return null;
  }
};

const Button = forwardRef<Ref, Props>(
  (
    {
      children,
      iconSide = "right",
      onClick,
      className,
      iconName,
      dark,
      withTail,
    },
    ref
  ) => {
    const icon = getIconFromName(iconName);

    return (
      <StyledButton
        ref={ref}
        dark={dark}
        onClick={onClick}
        className={clsx(
          className,
          withTail && "button-with-tail",
          iconSide === "left" ? "icon-left" : ""
        )}
      >
        <Typography white={dark} variant="buttons">
          {children}
        </Typography>
        {icon}
      </StyledButton>
    );
  }
);

export default Button;
