import styled from "@emotion/styled";

import { Typography } from "../Typography";
import { mobileSizeQuery } from "../../theme/common";

export const FooterContainer = styled.footer`
  padding: 40px 0 16px;
  background-color: #fffcf5;

  @media (${mobileSizeQuery}) {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (${mobileSizeQuery}) {
    flex-wrap: wrap;
  }

  .button {
    margin: 0 16px 5px;
  }
`;

export const AllRightsText = styled(Typography)`
  font-size: 8px;
  text-align: center;

  @media (${mobileSizeQuery}) {
    font-size: 12px;
    font-weight: bold;
    margin-top: 14px;
  }
`;
