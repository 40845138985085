import styled from "@emotion/styled";

import { MOBILE_SIZE } from "../../lib/constants";

export const LockWrapper = styled.div<{ mobHeight: number }>`
  width: 100vw;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30% 60px 0;
  overflow: hidden;

  img {
    transform: translate(-40px, 5px);
    width: 55%;
    min-width: 140px;
  }

  @media (max-height: ${MOBILE_SIZE}px) {
    height: ${({ mobHeight }) => mobHeight}px;
    padding: 0 60px 0 95px;
    flex-direction: row-reverse;
    justify-content: flex-end;

    img {
      align-self: flex-end;
      width: auto;
      height: 60%;
      margin-right: 72px;
      transform: translate(0, 5px);
    }

    .title:before {
      left: -8px;
      width: 2px;
      height: 21px;
    }
  }
`;
