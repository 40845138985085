import { RequestOptions } from "./types";
import { REACT_APP_API_URL, REACT_APP_API_KEY } from "../lib/constants";

class HttpClient {
  private apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  // TODO change this type to generic
  private async executeRequest({ url, method }: RequestOptions): Promise<any> {
    const headers = {
      "X-MICROCMS-API-KEY": REACT_APP_API_KEY
    };

    const options = {
      method,
      headers,
    };

    try {
      const requestUrl = `${this.apiUrl}/${url}`;
      const response = await fetch(requestUrl, options);

      return response.json();
    } catch (error) {
      // TODO handle error later
      throw error;
    }
  }

  async get(url = "") {
    return this.executeRequest({ url, method: "GET" });
  }
}

const httpClient = new HttpClient(REACT_APP_API_URL);

export default httpClient;
