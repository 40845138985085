import styled from "@emotion/styled";
import { mobileSizeQuery } from "../../theme/common";

export const ArtworkDescriptionRoot = styled.div`
  max-width: 440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 20px 25px;
  border-radius: 10px;
  margin-bottom: 8px;
  background-color: white;

  .name {
    text-align: center;
  }

  .author {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .info {
    color: ${(props) => props.theme.color.grey};
  }

  @media (${mobileSizeQuery}) {
    width: 100%;
    margin: 0;
    margin-top: 16px;
    text-align: center;
  }
`;

export const ArtworkDescriptionInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media (${mobileSizeQuery}) {
    align-items: center;
    flex-direction: column;

    .info {
      margin-top: 5px
    }
  }

  @media (not (${mobileSizeQuery})) and (max-width: 1060px) {
    /* 米田さん対応 */
    align-items: center;
    flex-direction: column;

    .info {
      margin-top: 2px
    }  
  }
`;
