declare global{
    interface Window {
        dataLayer: Array<Object>;
    }
}
type TabClickEvent = {
    event: 'tab_click';
    label: string;
}

type ImageExpandFromSceneEvent = {
    event: 'image_expand_from_scene';
    label: string;
}

type ImageExpandFromSceneInfoEvent = {
    event: 'image_expand_from_sceneInfo';
    label: string;
}

type QuestionnaireClickEvent = {
    event: 'questionnaire_click';
}

type SkipAnimationEvent = {
    event: 'skip_animation';
}

type GoArtworkListFromLandingEvent = {
    event: 'go_artwork_list_from_landingPage'
}

type GoArtworkListFromThanksEvent = {
    event: 'go_artwork_list_from_thanksPage'
}

type BubbleExpandEvent = {
    event: 'bubble_expand';
    label: string;
}

type GoArtworkDetails = {
    event: 'go_artwork_details';
    label: string;
}

type OpenPopup = {
    event: 'open_popup';
}

export type DataLayerType =
    | TabClickEvent
    | ImageExpandFromSceneEvent
    | ImageExpandFromSceneInfoEvent
    | QuestionnaireClickEvent
    | SkipAnimationEvent
    | GoArtworkListFromLandingEvent
    | GoArtworkListFromThanksEvent
    | BubbleExpandEvent
    | GoArtworkDetails
    | OpenPopup

export const pushDataLayer = (data: DataLayerType) =>{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
}