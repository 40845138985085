import styled from "@emotion/styled";
import { mobileSizeQuery } from "../../theme/common";

export const NewServiceWorkerVersionRoot = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 27px 32px;
  padding-right: 24px;
  gap: 25px;
  user-select: none;

  .header {
    line-height: 30px;
    margin-bottom: 5px;
  }

  .text {
    font-size: 14px;
  }

  .update-button {
    width: fit-content;
    margin-top: 10px;
  }

  @media (${mobileSizeQuery}) {
    align-items: center;
  }
`;

export const NewServiceWorkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (${mobileSizeQuery}) {
    gap: 12px;
  }
`;

export const CharacterIconImg = styled.img`
  width: 154px;
  height: 218px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 100px;
    height: 142px;
  }
`;
