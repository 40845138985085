import { Theme } from '@emotion/react';

export { global, reset } from './global';

declare module '@emotion/react' {
  export interface Theme {
    color: {
      white: string;
      warn: string;
      lightGrey: string;
      buttonTop: string;
      borderLight: string;
      gradient: string;
      grey: string;
      darkBrown: string;
      black: string;
      lightBlue: string;
      shadow: string;
    };
  }
}

export const theme: Theme = {
  color: {
    white: '#ffffff',
    warn: 'red',
    lightGrey: '#D9D9D9',
    buttonTop: '#F7F7F7',
    borderLight: '#FFFFFF80',
    gradient: '#ECF3F7',
    grey: '#464650',
    darkBrown: '#3d1f16',
    black: '#000000',
    lightBlue: '#446FB2',
    shadow: '#00000029',
  },
};