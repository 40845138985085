import { FC } from 'react'
import { TabPanelUnderlayer, TabPanelRoot, TabPanelContainer } from './TabPanel.style';

interface Props {
  value: number;
  index: number;
}

const TabPanel: FC<Props> = ({ value, index, children }) => {
  return value === index ? (
    <TabPanelUnderlayer>
      <TabPanelRoot>
        <TabPanelContainer>
          {children}
        </TabPanelContainer>
      </TabPanelRoot>
    </TabPanelUnderlayer>
  ) : null;
};

export default TabPanel;
