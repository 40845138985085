import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { Scene } from "./lib/scene";
import HttpClient from "./api/httpClient";
import imagePreloader from "./services/imagePreloader";

interface Story {
  id: string;
  storyNum: number;
  storyDescription: string;
  artworks: Scene[];
}

interface AppState {
  stories: Record<string, Story>;
  getStories: () => void;
}

export const useAppStore = create<AppState>()(
  devtools((set) => ({
    stories: {},
    getStories: async () => {
      const stories = await HttpClient.get();
      const artworks = stories.contents.flatMap(
        (content) => content.artworks || []
      );
      const arts = artworks.map((artwork) => ({
        id: artwork.id,
        src: artwork.picture.url,
      }));

      imagePreloader.addToQueue(arts);

      set(() => ({
        stories: stories.contents.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {}
        ),
      }));
    },
  }))
);
