import { FC, useState } from "react";
import Parser from "html-react-parser";

import { Typography } from "../Typography";
import { SceneQuestionProps } from "../../lib/scene";
import { useScreenSize } from "../../hooks/useScreenSize";
import { MIN_TABLET_SIZE } from "../../lib/constants";
import DetectiveImg from "../../icons/detective.svg";
import ModalPicture from "../ModalPicture";
import MessageImg from "../../icons/message.png";
import MagnifyImg from "../../icons/magnify.svg";

import {
  SceneComponentRoot,
  SceneComponentImgContainer,
  SceneComponentImg,
  SceneComponentComments,
  SceneComponentCommentRight,
  SceneComponentCommentLeft,
  SceneComponentCommentHeader,
  SceneComponentCommentFooter,
  MessageIcon,
  Detective,
  DescButton,
  MagnifyImage,
  ExpandMagnifyImage,
} from "./SceneComponent.style";

import { pushDataLayer } from '../../lib/analytics';

const IntroSceneZoom: FC<SceneQuestionProps> = (props) => {
  const { scene, goForward } = props;
  const [isCommentExpanded, setIsCommentExpanded] = useState(false);
  const [isPictureModalOpen, setIsPictureModalOpen] = useState<boolean>(false);
  const { innerWidth, innerHeight } = useScreenSize();

  return (
    <SceneComponentRoot mobHeight={innerHeight}>
      <DescButton onClick={() => {
        pushDataLayer({ event: 'go_artwork_details', label: scene.orderStory.toString() })
        goForward()
      }}>もっとくわしく</DescButton>
      <SceneComponentImgContainer
          onClick={() => {
            setIsPictureModalOpen(true)
            pushDataLayer({ event: 'image_expand_from_scene', label: scene.orderStory.toString() });
          }}
        >
        <SceneComponentImg
          id="image"
          src={scene.picture && scene.picture.url}
          className={scene.copyrights && "with-copyright"}
        />
        <ExpandMagnifyImage src={MagnifyImg} alt="magnify" />
        <Typography variant="copyright" className="copyright">
          {scene.copyrights}
        </Typography>
      </SceneComponentImgContainer>
      <SceneComponentComments>
        {!isCommentExpanded && (
          <>
            <SceneComponentCommentRight>
              <Typography variant="tabletMain">
                {scene.inquiryComment}
              </Typography>
            </SceneComponentCommentRight>
            <SceneComponentCommentLeft>
              <Typography variant="tabletMain">
                {scene.answerComment}
              </Typography>
            </SceneComponentCommentLeft>
          </>
        )}
        <SceneComponentCommentLeft
          className={`expandable ${isCommentExpanded && "expanded"}`}
          onClick={() => {
            if (!isCommentExpanded) {
              pushDataLayer({ event: 'bubble_expand', label: scene.orderStory.toString() });
            }
            setIsCommentExpanded(!isCommentExpanded)
          }}
        >
          <SceneComponentCommentHeader>
            <Typography variant="tabletMain" className="comment-header">
              {scene.answerAddComment}
            </Typography>
            <MagnifyImage src={MagnifyImg} alt="magnify" />
          </SceneComponentCommentHeader>
          {isCommentExpanded && (
            <>
              <Typography variant="tabletMain" className="expanded-text">
                {Parser(scene.descriptionComment)}
              </Typography>
              <SceneComponentCommentFooter>
                <MessageIcon src={MessageImg} />
                <Typography variant="tabletMain">
                  {Parser(scene.artworkInfomation)}
                </Typography>
              </SceneComponentCommentFooter>
            </>
          )}
        </SceneComponentCommentLeft>
      </SceneComponentComments>
      <ModalPicture
        isOpen={isPictureModalOpen}
        closeModal={() => setIsPictureModalOpen(false)}
        src={scene.picture && scene.picture.url}
        copyright={scene.copyrights}
      />
      {innerWidth > MIN_TABLET_SIZE && (
        <Detective src={DetectiveImg} alt="detective" />
      )}
    </SceneComponentRoot>
  );
};

export default IntroSceneZoom;
