interface Item {
  id: string;
  src: string;
}

class ImagePreloader {
  private readonly events: Record<string, Array<(args: any) => any>>;
  private loadingMap: Record<string, 'pending' | 'loaded'>;

  constructor() {
    this.events = {};
    this.loadingMap = {};
  }

  async addToQueue(items: Item | Item[]) {
    const arrayItems = Array.isArray(items) ? items : [items];

    for (let i = 0; i < arrayItems.length; i++) {
      const item = arrayItems[i];
      const { src } = item;

      if (this.loadingMap[src]) continue;

      this.loadingMap[src] = 'pending';
      await this.preloadImage(src);
      this.loadingMap[src] = 'loaded';
      this.emit("loaded", item);
    }
  }

  preloadImage(path) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = path;
      image.onload = resolve;
      image.onerror = reject;
    });
  }

  on(name: string, listener: (args: any) => any) {
    if (!this.events[name]) {
      this.events[name] = [];
    }

    this.events[name].push(listener);
  }

  removeListener(name: string, listenerToRemove: (args: any) => any) {
    if (!this.events[name]) {
      return;
    }

    const filterListeners = (listener) => listener !== listenerToRemove;

    this.events[name] = this.events[name].filter(filterListeners);
  }

  emit(name: string, data?: any) {
    if (!this.events[name]) {
      return;
    }

    this.events[name].forEach((cb) => cb(data));
  }
}

const imagePreloader = new ImagePreloader();

export default imagePreloader;
