import { Scene } from "./types";

export const sequence = (
  _order: number,
  scenes: Array<Scene>,
  direction: 'next' | 'prev',
): number | null => {
  const last = scenes[scenes.length - 1];
  const idx = scenes.findIndex(
    ({ orderStory }) => orderStory === _order,
  );

  if (idx !== -1 || last != null) {
    if (direction === 'next') {
      return scenes[idx + 1].orderStory;
    } else {
      return scenes[idx - 1].orderStory;
    }
  }

  return null;
};