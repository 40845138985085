import { useState, useEffect } from "react";
import { MOBILE_SIZE } from '../lib/constants';

interface Sizes {
  isMobileMode: boolean;
  innerWidth: number;
  innerHeight: number;
}

export function useScreenSize(): Sizes {
  const [screenSizes, setScreenSizes] = useState<Sizes>({
    isMobileMode: false,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight
  });

  useEffect(() => {
    function handleResize() {
      setScreenSizes({ isMobileMode: window.innerWidth < MOBILE_SIZE, innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return screenSizes;
}