import styled from "@emotion/styled";

const StyledButton = styled.button<{ dark?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 0;
  border-radius: 20px;
  background-color: ${({ dark, theme }) =>
    dark ? theme.color.darkBrown : "white"};
  filter: ${(props) => `drop-shadow(0 3pt 6pt ${props.theme.color.shadow})`};

  .icon {
    margin: 0 0 0 8px;
  }

  .right-arrow {
    transform: rotate(180deg);
  }

  &.button-with-tail::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    bottom: -21px;
    left: 35px;
    border-left: 15px solid white;
    border-right: 15px solid transparent;
    border-bottom: 20px solid transparent;
    border-top: 20px solid white;
    transform: rotate(25deg);
  }

  &.icon-left {
    flex-direction: row-reverse;

    .icon {
      margin: 0 8px 0 0;
    }
  }
`;

export default StyledButton;
