import styled from "@emotion/styled";

import Button from "../../components/Button";
import { mobileSizeQuery } from "../../theme/common";

export const SceneInfoRoot = styled.div`
  height: calc(100vh - 48px);
  padding-top: 107px;
  padding-bottom: 75px;
  padding-left: 80px;
  padding-right: 50px;
  display: flex;
  gap: 55px;

  @media (${mobileSizeQuery}) {
    height: unset;
    flex-direction: column;
    padding: 15px;
    padding-top: 84px;
    gap: 16px;
  }
`;

export const BackButton = styled(Button)`
  position: absolute;
  top: 16px;
  left: 16px;
  user-select: none;
`;

export const SceneInfoImgContainer = styled.div`
  position: relative;
  filter: ${(props) => `drop-shadow(5pt 5pt 1.5pt ${props.theme.color.shadow})`};

  .copyright {
    position: absolute;
    bottom: 12px;
    right: 12px;
    white-space: nowrap;
    color: ${(props) => props.theme.color.grey};
    font-size: 10px;
  }
`;

export const SceneInfoImg = styled.img`
  max-height: calc(100vh - 417px);
  width: unset;
  border: 8px solid white;
  border-radius: 3px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;
  border-bottom: 27px solid white;

  @media (${mobileSizeQuery}) {
    max-height: 350px;
    width: unset;
  }
`;

export const ExpandMagnifyImage = styled.img`
  position: absolute;
  bottom: 11px;
  left: 12px;
`;

export const SceneInfoLeft = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: center;
  justify-content: space-between;

  @media (${mobileSizeQuery}) {
    width: 100%;
  }
`;
