import {
  Children,
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Emitter } from "mitt";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import wizardEvents, { WizardTypeEvents } from "./emitter";
import { useLocation } from "react-router-dom";

interface WizardState {
  activePageIndex: number;
  steps: number;
}

enum WizardTypes {
  NEXT_PAGE = 1,
  PREV_PAGE,
  SET_STEP_COUNT,
  GO_TO_PAGE,
}

interface WizardActions {
  type: WizardTypes;
  payload?: number;
}

interface WizardContextState extends WizardState {
  goNextPage: () => void;
  goPrevPage: () => void;
  setSteps: (steps: number) => void;
  goToPage: (page: number) => void;
  wizardEvents: Emitter<WizardTypeEvents>;
}

const WizardContext = createContext<WizardContextState | null>(null);

export const useWizardContext = () => {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error(`[Wizard Error]: WizardContext is not found`);
  }
  return context;
};

export const useWizardProgress = () => {
  const { activePageIndex, steps } = useWizardContext();
  return {
    currentIndex: activePageIndex + 1,
    steps,
  };
};

export const useWizardButtons = () => {
  const { goToPage, goNextPage, goPrevPage, activePageIndex, steps } =
    useWizardContext();
  return {
    goToPage,
    goNextPage,
    goPrevPage,
    activePageIndex,
    steps,
  };
};

export const useWizardPages = (totalSteps: number) => {
  const { setSteps, activePageIndex } = useWizardContext();
  useEffect(() => {
    setSteps(totalSteps);
  }, [totalSteps, setSteps]);

  return {
    activePageIndex,
  };
};

const reducer = (state: WizardState, action: WizardActions): WizardState => {
  const { steps, activePageIndex } = state;
  switch (action.type) {
    case WizardTypes.NEXT_PAGE: {
      const newIndex = activePageIndex + 1;
      if (newIndex < steps) {
        return { ...state, activePageIndex: newIndex };
      }
      return state;
    }

    case WizardTypes.PREV_PAGE:
      if (activePageIndex > 0) {
        return { ...state, activePageIndex: activePageIndex - 1 };
      }
      return state;
    case WizardTypes.GO_TO_PAGE:
      return {
        ...state,
        activePageIndex: (action.payload as number) - 1,
      };
    case WizardTypes.SET_STEP_COUNT:
      return { ...state, steps: action.payload as number };
    default:
      return state;
  }
};

const Wizard: FC = ({ children }) => {
  const location = useLocation();
  const locationState = location.state as null | { startWizardPage: number };
  const startWizardPage = locationState?.startWizardPage;

  const initialState: WizardState = {
    activePageIndex: startWizardPage ? startWizardPage - 1 : 0,
    steps: 0,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const goNextPage = useCallback(() => {
    dispatch({ type: WizardTypes.NEXT_PAGE });
    wizardEvents.emit("onPageChange");
  }, []);

  const goPrevPage = useCallback(() => {
    dispatch({ type: WizardTypes.PREV_PAGE });
    wizardEvents.emit("onPageChange");
  }, []);

  const goToPage = useCallback((page: number) => {
    dispatch({ type: WizardTypes.GO_TO_PAGE, payload: page });
    wizardEvents.emit("onPageChange");
  }, []);

  const setSteps = useCallback(
    (steps) => {
      dispatch({ type: WizardTypes.SET_STEP_COUNT, payload: steps });
    },
    [dispatch]
  );
  const { activePageIndex, steps } = state;

  const context = {
    activePageIndex,
    steps,
    goNextPage,
    goPrevPage,
    goToPage,
    setSteps,
    wizardEvents,
  };
  return (
    <WizardContext.Provider value={context}>{children}</WizardContext.Provider>
  );
};

type Props = { className?: string };

const WizardPages: FC<Props> = ({ children, className, ...props }) => {
  const context = useWizardContext();

  if (!context) {
    throw new Error(`[Wizard Error]: WizardContext is not found`);
  }

  const { setSteps, activePageIndex } = context;
  const pages = Children.toArray(children);

  const steps = Children.count(children);

  useEffect(() => {
    setSteps(steps);
  }, [steps, setSteps]);

  const currentPage = pages[activePageIndex];
  return (
    <div className={className} {...props}>
      {currentPage}
    </div>
  );
};

const AnimatedWizardPages: FC<Props> = ({ children, className, ...props }) => {
  const context = useWizardContext();

  if (!context) {
    throw new Error(`[Wizard Error]: WizardContext is not found`);
  }

  const { setSteps, activePageIndex } = context;
  const pages = Children.toArray(children);

  const steps = Children.count(children);

  useEffect(() => {
    setSteps(steps);
  }, [steps, setSteps]);

  const currentPage = pages[activePageIndex];

  return (
    <div className={className} {...props}>
      <TransitionGroup>
        <CSSTransition
          classNames="fade-transition"
          key={activePageIndex}
          timeout={600}
        >
          {currentPage}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

interface OnChange {
  (): void;
}

const useWizardSpy = (onChange: OnChange) => {
  const { wizardEvents } = useWizardContext();

  useEffect(() => {
    wizardEvents.on("onPageChange", onChange);
    return () => wizardEvents.off("onPageChange", onChange);
  }, [wizardEvents, onChange]);

  return {};
};

// tracks on page change
const WizardSpy: FC<{ onChange: OnChange }> = ({ onChange }) => {
  useWizardSpy(onChange);
  return null;
};

export { WizardPages, WizardSpy, AnimatedWizardPages };
export default Wizard;
