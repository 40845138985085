import React, { Component } from "react";

import ErrorScreen from "./components/ErrorScreen";

interface Props {}

interface State {
  error: Error | null;
}

class ErrorHandler extends Component<Props, State> {
  state = {
    error: null,
  };

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.error) {
      return (
        <ErrorScreen
          buttonText="アートワークに戻る"
          onClick={this.handleReload}
        >
          Oops! Something went wrong.
        </ErrorScreen>
      );
    }

    return this.props.children;
  }
}

export default ErrorHandler;
