import styled from "@emotion/styled";
import { mobileSizeQuery } from "../../theme/common";

export const DescriptionTabsRoot = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1;

  .tab-content {
    line-height: 30px;

    a {
      color: ${(props) => props.theme.color.lightBlue};
      text-decoration: none;
    }
  }

  @media (${mobileSizeQuery}) {
    height: auto;
    margin-top: 0;
  }
`;

export const DescriptionTabsTabs = styled.div`
  width: 100%;
  display: flex;
  gap: 1px;
`;
