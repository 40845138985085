import { css } from "@emotion/react";

import { mobileSizeQuery } from "../../theme/common";

const typographyConfig = {
  // headline
  mobileH1: css`
    font-weight: bold;
    font-size: 30px;
    line-height: 52px;
  `,
  mobileH2: css`
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
  `,
  mobileH3: css`
    font-weight: normal;
    font-size: 22px;
    line-height: 20px;
  `,
  tabletH1: css`
    font-weight: bold;
    font-size: 60px;
    line-height: 52px;
  `,
  tabletH2: css`
    font-weight: normal;
    font-size: 40px;
    line-height: 53px;
  `,
  tabletH3: css`
    font-weight: normal;
    font-size: 28px;
    line-height: 37px;
  `,
  tabletH4: css`
    font-weight: normal;
    font-size: 24px;
    line-height: 64px;
  `,

  // body
  mobileMain: css`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  `,
  mobileSmall: css`
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 16px;
    font-weight: lighter;
  `,
  tabletMain: css`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  `,
  tabletSmall: css`
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 16px;
    font-weight: lighter;
  `,
  mobileTitle: css`
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
  `,
  tabletTitle: css`
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
  `,
  buttons: css`
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
  `,
  copyright: css`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  `,
  copyrightMobile: css`
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  `,
};

export const verticalLine = css`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 4px;
    height: 43px;
    background-color: white;
    left: -12px;

    @media (${mobileSizeQuery}) {
      left: -8px;
      width: 2px;
      height: 21px;
    }
  }
`;

const variantsMapping = {
  // headline
  mobileH1: "h1",
  mobileH2: "h2",
  mobileH3: "h3",
  tabletH1: "h1",
  tabletH2: "h2",
  tabletH3: "h3",
  tabletH4: "h4",

  // body
  mobileMain: "p",
  mobileSmall: "p",
  tabletMain: "p",
  tabletSmall: "p",
  mobileTitle: "p",
  tabletTitle: "p",
  buttons: "span",
  copyright: "p",
  copyrightMobile: "p",
};

export { typographyConfig, variantsMapping };
export type Variants = keyof typeof typographyConfig;
