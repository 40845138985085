import { FC } from "react";
import { Typography } from "../Typography";

import { FooterContainer, FooterLinksContainer } from "./CopyrightFooter.style";
import { useScreenSize } from "../../hooks/useScreenSize";

const CopyrightFooter: FC = () => {
  const { isMobileMode } = useScreenSize();
  const copyrightVariant = isMobileMode ? "copyrightMobile" : "copyright";

  return (
    <FooterContainer>
      <FooterLinksContainer>
        <Typography variant={copyrightVariant} className="button">
          <a
            href="https://www.nri.com/jp/site/notice"
            target="_blank"
            rel="noreferrer"
          >
            免責事項
          </a>
        </Typography>
        <Typography variant={copyrightVariant} className="button">
          <a
            href="https://www.nri.com/jp/site/right"
            target="_blank"
            rel="noreferrer"
          >
            サイト利用規定
          </a>
        </Typography>
        <Typography variant={copyrightVariant} className="button">
          <a
            href="https://www.nri.com/jp/site/security"
            target="_blank"
            rel="noreferrer"
          >
            個人情報保護方針
          </a>
        </Typography>

        <Typography variant={copyrightVariant} className="button">
          <a
            href="https://www.nri.com/jp/site/privacy"
            target="_blank"
            rel="noreferrer"
          >
            個人情報の取り扱いについて
          </a>
        </Typography>

        <Typography variant={copyrightVariant} className="button">
          <a
            href="https://www.nri.com/jp/site/security_declare"
            target="_blank"
            rel="noreferrer"
          >
            情報セキュリティ対策についての宣言文
          </a>
        </Typography>
      </FooterLinksContainer>
      <Typography center variant={copyrightVariant}>
        ©Nomura Research Institute, Ltd. All rights reserved.
      </Typography>
    </FooterContainer>
  );
};

export default CopyrightFooter;
