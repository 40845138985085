import { FC } from "react";
import Modal from "react-modal";

import { Typography } from "../Typography";

import { ReactComponent as CloseLightIcon } from "../../icons/close-light.svg";
import { ReactComponent as HandIcon } from "../../icons/hand.svg";
import {
  ModalPictureRoot,
  CloseButton,
  ZoomedPicture,
} from "./ModalPicture.style";
import { theme } from "../../theme";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  src: string;
  copyright?: string;
}

const styles = {
  overlay: {
    backgroundColor: theme.color.black,
    zIndex: 10,
    overflow: "hidden",
  },
  content: {
    backgroundColor: theme.color.black,
    border: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },
};

const ModalPicture: FC<Props> = ({ isOpen, closeModal, src, copyright }) => (
  <Modal ariaHideApp={false} isOpen={isOpen} style={styles}>
    <ModalPictureRoot>
      <CloseButton onClick={closeModal}>
        <Typography variant="tabletMain" className="close-text">
          とじる
        </Typography>
        <CloseLightIcon />
      </CloseButton>
      <Typography white className="zoom-info" variant="mobileH2">
        <HandIcon className="zoom-icon" />
        ズームできるよ！
      </Typography>
      <ZoomedPicture src={src} />
      <Typography className="copyright" variant="copyright">
        {copyright}
      </Typography>
    </ModalPictureRoot>
  </Modal>
);

export default ModalPicture;
