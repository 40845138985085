import React, { FC, useEffect, useState } from "react";
import DeviceOrientation, { Orientation } from "react-screen-orientation";

import { useScreenSize } from "../../hooks/useScreenSize";
import { Typography } from "../Typography";
import Header from "../Header";
import { LockWrapper } from "./DeviceOrientationProvider.style";
import CharImg from "../../icons/character-granny.svg";
import { MOBILE_SIZE, MOBILE_SIZE_HEIGHT } from "../../lib/constants";

const DeviceOrientationProvider: FC = ({ children }) => {
  const [screenOrientation, setScreenOrientation] = useState("");

  const { innerHeight, innerWidth } = useScreenSize();
  const errorVariant =
    screenOrientation === "portrait" ? "mobileH2" : "tabletH2";

  useEffect(() => {
    if (innerWidth < MOBILE_SIZE || innerHeight < MOBILE_SIZE_HEIGHT) {
      setScreenOrientation("portrait");
    } else {
      setScreenOrientation("landscape");
    }
  }, []);

  const displayOrientation =
    screenOrientation === "portrait" ? "portrait" : "landscape";
  const displayLockedOrientation =
    screenOrientation === "portrait" ? "landscape" : "portrait";

  return (
    <DeviceOrientation lockOrientation={displayOrientation}>
      <Orientation orientation={displayOrientation} alwaysRender={false}>
        {children}
      </Orientation>
      <Orientation orientation={displayLockedOrientation} alwaysRender={false}>
        <div>
          {screenOrientation === "landscape" && <Header />}
          <LockWrapper mobHeight={innerHeight}>
            {screenOrientation === "landscape" && (
              <Typography
                white
                withLine
                variant={errorVariant}
                className="title"
              >
                タブレットを横向きにしてね
              </Typography>
            )}
            {screenOrientation === "portrait" && (
              <Typography
                white
                withLine
                variant={errorVariant}
                className="title"
              >
                スマートフォンを縦向きにしてね
              </Typography>
            )}
            <img src={CharImg} />
          </LockWrapper>
        </div>
      </Orientation>
    </DeviceOrientation>
  );
};

export default DeviceOrientationProvider;
