import { FC } from "react";
import { Link } from "react-router-dom";

import { Typography } from "../Typography";
import { HeaderRoot, HeaderTitleImg, HeaderLink } from "./Header.style";
import LogoImg from "../../icons/header_logo.svg";

const Header: FC = () => (
  <HeaderRoot>
    <Link to="/" className="link">
      <HeaderTitleImg src={LogoImg} alt="title" />
    </Link>
    <HeaderLink
      href="https://yokohama.art.museum/index.html"
      target="_blank"
    >
      <Typography variant="tabletSmall">YOKOHAMA MUSEUM OF ART</Typography>
    </HeaderLink>
  </HeaderRoot>
);

export default Header;
