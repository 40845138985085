import styled from '@emotion/styled';
import { mobileSizeQuery } from '../../theme/common';

export const TabRoot = styled.div`
  flex-grow: 1;
  padding: 1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${(props) => `linear-gradient(to right, ${props.theme.color.white}, ${props.theme.color.gradient})`};

  @media (${mobileSizeQuery}) {
    flex-grow: 1;
  }
`;

export const TabContent = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.lightGrey};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  user-select: none;

  @media (${mobileSizeQuery}) {
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &.active {
    background-color: ${(props) => props.theme.color.buttonTop};
  }
`;