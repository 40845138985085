import { FC } from "react";
import Modal from "react-modal";

import { useServiceWorker } from "../../hooks/useServiceWorker";
import { useScreenSize } from "../../hooks/useScreenSize";
import {
  NewServiceWorkerVersionRoot,
  NewServiceWorkerContainer,
  CharacterIconImg,
} from "./NewServiceWorkerVersion.style";
import { Typography } from "../Typography";
import Button from "../Button";
import CharacterIcon from "../../icons/character-granny.svg";

const NewServiceWorkerVersion: FC = () => {
  const { newVersion, updateServiceWorker } = useServiceWorker();
  const { isMobileMode } = useScreenSize();

  const modalStyles = {
    overlay: {
      background: "#00000066",
      zIndex: 10,
    },
    content: {
      width: isMobileMode ? "95%" : "unset",
      maxWidth: "500px",
      height: "fit-content",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      border: 0,
      borderRadius: "10px",
      padding: 0,
      filter: "drop-shadow(0 20px 20px #00000029)",
    },
  };

  return (
    <Modal isOpen={newVersion} style={modalStyles}>
      <NewServiceWorkerVersionRoot>
        <NewServiceWorkerContainer>
          <Typography
            className="header"
            variant={isMobileMode ? "mobileH2" : "tabletH4"}
          >
            更新のおしらせ
          </Typography>
          <Typography
            variant={isMobileMode ? "mobileMain" : "tabletMain"}
            className="text"
          >
            アプリがアップデートされました。
          </Typography>
          <Button dark className="update-button" onClick={updateServiceWorker}>
            オーケー！
          </Button>
        </NewServiceWorkerContainer>
        <CharacterIconImg src={CharacterIcon} />
      </NewServiceWorkerVersionRoot>
    </Modal>
  );
};

export default NewServiceWorkerVersion;
