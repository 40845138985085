import { FC } from "react";

import { GeneralLayoutRoot, MainContent } from "./GeneralLayout.style";
import Header from "../Header";
import { useScreenSize } from "../../hooks/useScreenSize";

const GeneralLayout: FC = ({ children }) => {
  const { isMobileMode, innerHeight } = useScreenSize();

  return (
    <GeneralLayoutRoot>
      {!isMobileMode && <Header />}
      <MainContent mobHeight={innerHeight}>{children}</MainContent>
    </GeneralLayoutRoot>
  );
};

export default GeneralLayout;
