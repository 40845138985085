import { useState, useEffect, FC } from "react";
import Parser from "html-react-parser";
import { useScreenSize } from "../../hooks/useScreenSize";
import {
  DescriptionTabsRoot,
  DescriptionTabsTabs,
} from "./DescriptionTabs.style";
import { Typography } from "../Typography";

import TabPanel from "../TabPanel";
import Tab from "../Tab";

import { pushDataLayer } from "../../lib/analytics";

interface Props {
  description: string;
  fullDescription: string;
  trivia: string;
}

const DescriptionTabs: FC<Props> = ({
  description,
  fullDescription,
  trivia,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const { isMobileMode } = useScreenSize();
  const tabPanelVariant = isMobileMode ? "mobileMain" : "tabletMain";

  useEffect(() => {
    pushDataLayer({ event: "tab_click", label: "tab" + activeTab });
  }, [activeTab]);

  const handleActveTab = (index: number) => {
    setActiveTab(index);
  };

  return (
    <DescriptionTabsRoot>
      <DescriptionTabsTabs>
        <Tab index={0} value={activeTab} handleActveTab={handleActveTab}>
          <Typography variant="tabletMain" className="tab">
            もっとみる
          </Typography>
        </Tab>
        <Tab index={1} value={activeTab} handleActveTab={handleActveTab}>
          <Typography variant="tabletMain" className="tab">
            もっとしる
          </Typography>
        </Tab>
        <Tab index={2} value={activeTab} handleActveTab={handleActveTab}>
          <Typography variant="tabletMain" className="tab">
            まめちしき
          </Typography>
        </Tab>
      </DescriptionTabsTabs>
      <TabPanel value={activeTab} index={0}>
        <Typography className="tab-content" variant={tabPanelVariant}>
          {Parser(description)}
        </Typography>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Typography className="tab-content" variant={tabPanelVariant}>
          {Parser(fullDescription)}
        </Typography>
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <Typography className="tab-content" variant={tabPanelVariant}>
          {Parser(trivia)}
        </Typography>
      </TabPanel>
    </DescriptionTabsRoot>
  );
};

export default DescriptionTabs;
