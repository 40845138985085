import { useNavigate } from "react-router-dom";

import { useScreenSize } from "../../hooks/useScreenSize";
import CopyrightFooter from "../../components/CopyrightFooter";
import { SECOND_STORY_ID } from "../../lib/constants";
import {
  ThanksLayoutRootDiv,
  ThanksBodyContainer,
  ImageContainer,
  ThanksImg,
  ThanksContentDiv,
  ThanksTitleDiv,
  ThanksDescriptionDiv,
  ThanksButtonGroupDiv,
  NextButton,
  Floor,
  BackButton,
  ThanksTitle,
  ThanksDescription,
  ThanksButton,
} from "./Thanks.style";
import FourthCharacter from "../../icons/4th_char_01@2x.svg";
import FourthCharacterFlip from "../../icons/4th_char_01@2x_flip.svg";

import { pushDataLayer } from "../../lib/analytics";

const Thanks = () => {
  const { isMobileMode, innerWidth, innerHeight } = useScreenSize();
  const typographyVariantMain = isMobileMode ? "mobileMain" : "tabletMain";
  const typographyVariantH1 = isMobileMode ? "mobileH1" : "tabletH1";
  const navigate = useNavigate();
  const handleClick = () => {
    pushDataLayer({ event: "questionnaire_click" });
    window.open(
      "https://jp.surveymonkey.com/r/PV3NTC3",
      "_blank",
      "noreferrer"
    );
  };

  return (
    <ThanksLayoutRootDiv mobHeight={innerHeight}>
      <BackButton
        iconName="left-arrow"
        iconSide="left"
        onClick={() => navigate(-1)}
      >
        もどる
      </BackButton>
      <ThanksBodyContainer>
        {innerWidth > 890 && (
          <ImageContainer>
            <ThanksImg src={FourthCharacter} />
          </ImageContainer>
        )}
        <ThanksContentDiv>
          <ThanksTitleDiv>
            <ThanksTitle white variant={typographyVariantH1}>
              ありがとう！
            </ThanksTitle>
            {innerWidth <= 890 && (
              <ThanksImg src={FourthCharacterFlip} />
            )}
          </ThanksTitleDiv>
          <ThanksDescriptionDiv>
            <ThanksDescription variant={typographyVariantMain}>
              全部の作品をみてくれてありがとう。
              <br />
              こまかいところまでズームして、じっくり見られたかな？
              <br />
              作品をみて気づいたことや感じたことがあったら、だれかに話したり文章に書いたりしてみよう。
              <br />
              くり返し作品をみると、きっと新しい発見があるはず！
              <br />
              何度でも遊びに来てね。
            </ThanksDescription>
          </ThanksDescriptionDiv>
          <ThanksButtonGroupDiv>
            <ThanksButton
              iconName="left-arrow"
              iconSide="left"
              className="main-page"
              onClick={() => navigate(`/`)}
            >
              最初にもどる
            </ThanksButton>
            <ThanksButton
              iconName="list"
              className="all-artworks-page"
              onClick={() => {
                pushDataLayer({ event: 'go_artwork_list_from_thanksPage' });
                navigate(`/scenes/${SECOND_STORY_ID}`)
              }
              }
            >
              作品リストをみる
            </ThanksButton>
            <NextButton dark iconName="right-arrow" onClick={handleClick}>
              アンケートに答える
            </NextButton>
          </ThanksButtonGroupDiv>
        </ThanksContentDiv>
        {innerWidth > 890 && <Floor />}
      </ThanksBodyContainer>
      {!isMobileMode && <CopyrightFooter />}
    </ThanksLayoutRootDiv>
  );
};

export default Thanks;
