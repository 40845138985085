import styled from "@emotion/styled";

import { mobileSizeQuery } from "../../theme/common";

export const Caret = styled.span`
  display: inline-block;
  width: 4px;
  height: 40px;
  background-color: white;
  margin-left: 8px;
  transform: translateY(4px);
  animation: blink linear 1s infinite;

  @media (${mobileSizeQuery}) {
    margin-left: 4px;
    width: 2px;
    height: 20px;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;
