import { FC, useState } from "react";

import { useScreenSize } from "../../hooks/useScreenSize";
import { SceneQuestionProps } from "../../lib/scene";
import DetectiveImg from "../../icons/detective-question-right.svg";
import { SceneQuestionRoot, Detective } from "./SceneQuestion.style";
import { Typography } from "../Typography";
import TypingText from "../../components/TypingText";

const SceneQuestion: FC<SceneQuestionProps> = ({ scene }) => {
  const { isMobileMode, innerHeight } = useScreenSize();
  const questionVariant = isMobileMode ? "mobileH2" : "tabletH2";
  const [ isDetectiveAnimation, setDetectiveAnimation ] = useState(false);

  return (
    <SceneQuestionRoot mobHeight={innerHeight}>
      <Detective src={DetectiveImg} alt="detective"
        className={ isDetectiveAnimation ? "detective-activated" : "" }
        onClick={() => {setDetectiveAnimation(true);}}
        />
      <Typography white variant={questionVariant} className="question">
        <TypingText text={scene.introductionText} />
      </Typography>
    </SceneQuestionRoot>
  );
};

export default SceneQuestion;
