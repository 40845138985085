import styled from "@emotion/styled";

import Button from "../../components/Button";
import { mobileSizeQuery } from "../../theme/common";

export const BackButton = styled(Button)`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
  user-select: none;

  @media (${mobileSizeQuery}) {
    &.withHeader {
      top: 64px;
    }
  }
`;

export const NextButton = styled(Button)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 2;
  user-select: none;

  &.absolute {
    position: absolute;
  }
`;
