import { useEffect, useRef, useState } from "react";
import { Workbox } from 'workbox-window';

export const useServiceWorker = () => {
  const [newVersion, setNewVersion] = useState(false);
  const wb = useRef<Workbox | null>(null);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      wb.current = new Workbox(`${process.env.PUBLIC_URL}/service-worker.js`);
      wb.current.addEventListener('waiting', () => setNewVersion(true));
      wb.current.register();
    }
  }, []);

  const updateServiceWorker = () => {
    if ('serviceWorker' in navigator && wb.current !== null) {
      wb.current.addEventListener('controlling', () => {
        setNewVersion(false);
        window.location.reload();
      });
      wb.current.messageSkipWaiting();
    }
  };

  return {
    newVersion,
    updateServiceWorker,
    setNewVersion,
  };
}