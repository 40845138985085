import React from "react";
import { useNavigate } from "react-router-dom";

import ErrorScreen from "../ErrorScreen";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <ErrorScreen
      buttonText="トップページへ"
      buttonIcon="list"
      onClick={() => navigate(`/`)}
    >
      おっと <br /> エラーが発生しました
    </ErrorScreen>
  );
};
export default NotFound;
