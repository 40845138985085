import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Home from "./views/Home";
import Scenes from "./views/Scenes";
import Scene from "./views/Scene";
import Thanks from "./views/Thanks";
import NotFound from "./components/NotFound";
import Wizard from "./components/Wizard/Wizard";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames="fade-transition"
        timeout={600}
      >
        <Routes location={location}>
          <Route index element={<Home />} />
          <Route path="scenes/:storyName" element={<Scenes />} />
          <Route
            path=":storyName/scene/:sceneId"
            element={
              <Wizard>
                <Scene />
              </Wizard>
            }
          />
          <Route path=":storyName/thanks" element={<Thanks />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};
export default AnimatedRoutes;
