import styled from "@emotion/styled";
import { mobileSizeQuery } from "../../theme/common";

import { Typography } from "../Typography";
import { ReactComponent as CloseLightIcon } from "../../icons/close-light.svg";

export const SiteCloseMessageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  min-width: 500px;
  padding: 35px 32px 35px;
  background: #fff;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  filter: ${(props) => `drop-shadow(0 3pt 6pt ${props.theme.color.shadow})`};

  @media (${mobileSizeQuery}) {
    min-width: unset;
    left: 16px;
    right: 16px;
    transform: translate(0, -50%);
  }

  &.main-popup-enter {
    opacity: 0;
    transform: translate(-50%, calc(50px - 50%));

    @media (${mobileSizeQuery}) {
      transform: translate(0, calc(50px - 50%));
    }
  }

  &.main-popup-enter-active {
    opacity: 1;
    transition: opacity 600ms, transform 600ms;
    transform: translate(-50%, -50%);

    @media (${mobileSizeQuery}) {
      transform: translate(0, -50%);
    }
  }

  &.main-popup-exit {
    opacity: 1;
    transform: translate(-50%, -50%);

    @media (${mobileSizeQuery}) {
      transform: translate(0, -50%);
    }
  }

  &.main-popup-exit-active {
    opacity: 0;
    transition: opacity 600ms, transform 600ms;
    transform: translate(-50%, calc(50px - 50%));

    @media (${mobileSizeQuery}) {
      transform: translate(0, calc(50px - 50%));
    }
  }
`;

export const CloseIcon = styled(CloseLightIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  fill: #000;
`;

export const SiteCloseMessageTitle = styled(Typography)`
  margin-bottom: 20px;
  padding-left: 13px;

  @media (${mobileSizeQuery}) {
    padding-left: 0;
  }
`;

export const SiteCloseMessageInfo = styled(Typography)`
  margin-top: 10px;
`;

export const SiteCloseMessageFooter = styled(Typography)`
  margin-top: 20px;
  text-align: left;
`;
