import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import {
  ErrorContainer,
  ImageContainer,
  Image,
  ContentDiv,
  BackButton,
} from "./ErrorScreen.style";
import { Typography } from "../Typography";
import { useScreenSize } from "../../hooks/useScreenSize";
import CharImg from "../../icons/char_big_01@2x.svg";
import { Icons } from "../Button/Button";

type Props = {
  children: ReactNode;
  buttonText?: string;
  buttonIcon?: Icons;
  onClick?: () => void;
};

const ErrorScreen: FC<Props> = ({
  children,
  buttonText,
  buttonIcon,
  onClick,
}) => {
  const { isMobileMode, innerHeight } = useScreenSize();
  const errorVariant = isMobileMode ? "mobileH2" : "tabletH2";

  return (
    <ErrorContainer mobHeight={innerHeight}>
      <ImageContainer>
        <Image src={CharImg} alt="" />
      </ImageContainer>
      <ContentDiv>
        <Typography white withLine variant={errorVariant}>
          {children}
        </Typography>
        {buttonText && (
          <BackButton iconName={buttonIcon} onClick={onClick}>
            {buttonText}
          </BackButton>
        )}
      </ContentDiv>
    </ErrorContainer>
  );
};

export default ErrorScreen;
