import styled from "@emotion/styled";
import { mobileSizeQuery } from "../../theme/common";

export const ModalPictureRoot = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  user-select: none;

  .zoom-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .zoom-icon {
    margin-right: 8px;
    transform: translateY(-3px);
  }

  .copyright {
    position: absolute;
    color: white;
    font-size: 10px;
    bottom: 6px;
    right: 10px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  gap: 5px;
  background: transparent;
  border: 0;
  align-items: center;

  .close-text {
    color: white;
  }
`;

export const ZoomedPicture = styled.img`
  max-height: 88%;
  align-self: center;
  border: 8px solid white;
  border-radius: 3px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;
`;
