import styled from "@emotion/styled";
import { mobileSizeQuery, maxTabletSizeQuery } from "../../theme/common";

export const HomeGalleryContainer = styled.div`
  position: relative;
  margin-top: 44px;
  z-index: 1;
`;

export const HomeGalleryPictures = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;

  &.static {
    animation-name: pictures;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @keyframes pictures {
      0% {
        transform: translateX(-25px);
      }

      50% {
        transform: translateX(25px);
      }

      100% {
        transform: translateX(-25px);
      }
    }

    @media (${maxTabletSizeQuery}) {
      animation-name: picturesDesktop;

      @keyframes picturesDesktop {
        0% {
          transform: translateX(-30px);
        }

        50% {
          transform: translateX(30px);
        }

        100% {
          transform: translateX(-30px);
        }
      }
    }

    @media (${mobileSizeQuery}) {
      animation-name: picturesMobile;

      @keyframes picturesMobile {
        0% {
          transform: translateX(-20px);
        }

        50% {
          transform: translateX(20px);
        }

        100% {
          transform: translateX(-20px);
        }
      }
    }
  }
`;

const GallerySide = styled.div`
  display: flex;
  width: 50%;
`;

export const LeftGalleryPartDiv = styled(GallerySide)`
  justify-content: flex-end;
  padding: 10px 156px 0 0;
`;

export const RightGalleryPartDiv = styled(GallerySide)`
  padding: 0 0 0 68px;

  @media (${mobileSizeQuery}) {
    padding-left: 40px;
  }
`;

export const FirstArt = styled.img`
  width: 224px;
  height: 190px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 163px;
    height: 140px;
    position: absolute;
    left: -50px;
  }
`;

export const SecondArt = styled.img`
  width: 124px;
  height: 190px;
  margin: 64px 37px 0 0;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 85px;
    height: 130px;
    margin: 43px 26px 0 0;
  }
`;

export const ThirdArt = styled.img`
  width: 150px;
  height: 273px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 102px;
    height: 186px;
  }
`;

export const CharactersDiv = styled.div`
  position: absolute;
  top: 77px;
  right: 0;
  left: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (${mobileSizeQuery}) {
    top: 65px;
  }

  &.static {
    width: 58%;
    animation-name: characters;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @keyframes characters {
      0% {
        transform: translateX(calc(-50% - 70px));
      }

      50% {
        transform: translateX(calc(-50% + 70px));
      }

      100% {
        transform: translateX(calc(-50% - 70px));
      }
    }

    @media (${maxTabletSizeQuery}) {
      animation-name: charactersDesktop;
      width: 40%;

      @keyframes charactersDesktop {
        0% {
          transform: translateX(calc(-50% - 80px));
        }

        50% {
          transform: translateX(calc(-50% + 80px));
        }

        100% {
          transform: translateX(calc(-50% - 80px));
        }
      }
    }

    @media (${mobileSizeQuery}) {
      animation-name: charactersMobile;
      width: 100%;

      @keyframes charactersMobile {
        0% {
          transform: translateX(calc(-50% - 50px));
        }

        50% {
          transform: translateX(calc(-50% + 50px));
        }

        100% {
          transform: translateX(calc(-50% - 50px));
        }
      }
    }
  }
`;

export const ThirdCharacterImg = styled.img`
  width: 120px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 81px;
  }

  &.static {
    animation-name: thirdCharacter;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @keyframes thirdCharacter {
      0% {
        transform: translateX(-15px);
      }

      25% {
        transform: translateX(0px);
      }

      50% {
        transform: translateX(-15px);
      }

      75% {
        transform: translateX(0px);
      }

      100% {
        transform: translateX(-15px);
      }
    }

    @media (${mobileSizeQuery}) {
      animation-name: thirdCharacterMobile;
      @keyframes thirdCharacterMobile {
        0% {
          transform: translateX(-7.5px);
        }

        25% {
          transform: translateX(0px);
        }

        50% {
          transform: translateX(-7.5px);
        }

        75% {
          transform: translateX(0px);
        }

        100% {
          transform: translateX(-7.5px);
        }
      }
    }
  }
`;

export const FourthCharacterImg = styled.img`
  width: 130px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 88px;
  }

  &.static {
    animation-name: fourthCharacter;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @keyframes fourthCharacter {
      0% {
        transform: translateX(-5px);
      }

      25% {
        transform: translateX(0px);
      }

      50% {
        transform: translateX(-5px);
      }

      75% {
        transform: translateX(0px);
      }

      100% {
        transform: translateX(-5px);
      }
    }

    @media (${mobileSizeQuery}) {
      animation-name: fourthCharacterMobile;
      @keyframes fourthCharacterMobile {
        0% {
          transform: translateX(-2.5px);
        }

        25% {
          transform: translateX(0px);
        }

        50% {
          transform: translateX(-2.5px);
        }

        75% {
          transform: translateX(0px);
        }

        100% {
          transform: translateX(-2.5px);
        }
      }
    }
  }
`;

export const SecondCharacterImg = styled.img`
  width: 106px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 72px;
  }

  &.static {
    animation-name: secondCharacter;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @keyframes secondCharacter {
      0% {
        transform: translateX(5px);
      }

      25% {
        transform: translateX(0px);
      }

      50% {
        transform: translateX(5px);
      }

      75% {
        transform: translateX(0px);
      }

      100% {
        transform: translateX(5px);
      }
    }

    @media (${mobileSizeQuery}) {
      animation-name: secondCharacterMobileile;

      @keyframes secondCharacterMobileile {
        0% {
          transform: translateX(2.5px);
        }

        25% {
          transform: translateX(0px);
        }

        50% {
          transform: translateX(2.5px);
        }

        75% {
          transform: translateX(0px);
        }

        100% {
          transform: translateX(2.5px);
        }
      }
    }
  }
`;

export const FirstCharacterImg = styled.img`
  width: 166px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-drag: none;
  -webkit-user-drag: none;

  @media (${mobileSizeQuery}) {
    width: 115px;
  }

  &.static {
    animation-name: firstCharacter;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @keyframes firstCharacter {
      0% {
        transform: translateX(15px);
      }

      25% {
        transform: translateX(0px);
      }

      50% {
        transform: translateX(15px);
      }

      75% {
        transform: translateX(0px);
      }

      100% {
        transform: translateX(15px);
      }
    }

    @media (${mobileSizeQuery}) {
      animation-name: firstCharacterMobile;

      @keyframes firstCharacterMobile {
        0% {
          transform: translateX(7.5px);
        }

        25% {
          transform: translateX(0px);
        }

        50% {
          transform: translateX(7.5px);
        }

        75% {
          transform: translateX(0px);
        }

        100% {
          transform: translateX(7.5px);
        }
      }
    }
  }
`;
