import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useWizardButtons, AnimatedWizardPages } from "../../components/Wizard";
import { sequence } from "../../lib/scene/utils";
import { useAppStore } from "../../store";
import SceneInfo from "../../components/SceneInfo";
import SceneQuestion from "../../components/SceneQuestion";
import SceneComponent from "../../components/SceneComponent";
import SceneZoom from "../../components/SceneZoom/SceneZoom";
import Navigation from "../../components/Navigation/Navigation";
import { SceneRoot } from "./Scene.style";
import Header from "../../components/Header";
import { useScreenSize } from "../../hooks/useScreenSize";

const Scene = () => {
  const { sceneId, storyName } =
    useParams<{ sceneId: string; storyName: string }>();

  const story = useAppStore((state) => state.stories[storyName as string]);

  const scenes = story ? story.artworks : [];
  const scene = sceneId
    ? scenes.find((scene) => scene.orderStory === +sceneId)
    : undefined;
  const last = scenes[scenes.length - 1];

  const navigate = useNavigate();
  const { goNextPage, goToPage, activePageIndex } = useWizardButtons();

  const { isMobileMode } = useScreenSize();

  const onNext = (scene) => {
    if (scene.id === last.id && activePageIndex !== 0) {
      // TODO refactor it in future after all pages will be created
      navigate(`/${storyName}/thanks`);
    } else if (activePageIndex === 0) {
      goToPage(2);
    } else {
      navigate(
        `/${storyName}/scene/${sequence(scene.orderStory, scenes, "next")}`
      );
      goToPage(1);
    }
  };

  const onBack = (scene) => {
    if (activePageIndex === 0) {
      if (scene.orderStory === 1) {
        navigate(`/`);
        return;
      }
      navigate(
        `/${storyName}/scene/${sequence(scene.orderStory, scenes, "prev")}`
      );
      goToPage(1);
    } else {
      goToPage(1);
    }
  };

  if (!scene) {
    return null;
  }

  return (
    <SceneRoot>
      {isMobileMode && scene && activePageIndex === 0 && <Header />}
      <AnimatedWizardPages>
        {scene && <SceneQuestion scene={scene} goForward={goNextPage} />}
        {scene && <SceneZoom scene={scene} goForward={goNextPage} />}
        {scene && <SceneComponent scene={scene} goForward={goNextPage} />}
        {scene && <SceneInfo scene={scene} goToPage={goToPage} />}
      </AnimatedWizardPages>
      {scene && activePageIndex !== 1 && activePageIndex !== 3 && (
        <Navigation
          activePageIndex={activePageIndex}
          onBack={() => onBack(scene)}
          onNext={() => onNext(scene)}
        />
      )}
    </SceneRoot>
  );
};

export default Scene;
