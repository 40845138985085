import styled from '@emotion/styled';
import { mobileSizeQuery } from '../../theme/common';

export const TabPanelUnderlayer = styled.div`
  padding: 1px;
  background: ${(props) => `linear-gradient(to right, ${props.theme.color.white}, ${props.theme.color.gradient})`};
  border-radius: 8px; 
  transform: translateY(-8px); 
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (${mobileSizeQuery}) {
    border-radius: 0; 
    transform: translateY(0px); 
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const TabPanelRoot = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${(props) => props.theme.color.buttonTop};
  border-radius: 8px; 
  display: flex;
  flex-direction: column;
  flex: 1;

  a {
    cursor: revert;
    color:#0000EE;
    text-decoration:underline;
  }
`;

export const TabPanelContainer = styled.div`
  overflow: auto;
  padding-left: 30px; 
  padding-right: 30px; 
  flex: 1 1 auto;
  height: 0;

  @media (${mobileSizeQuery}) {
    overflow: visible;
    height: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;