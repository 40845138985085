import styled from "@emotion/styled";
import {
  typographyConfig,
  Variants,
  variantsMapping,
  verticalLine,
} from "./typographyConfig";

export const create = (variant: Variants | undefined) => {
  const element = variantsMapping[
    variant as Variants
  ] as keyof JSX.IntrinsicElements;

  if (!element) {
    throw Error(`Variant mapping is not supported. Variant: ${variant}`);
  }

  const styledEl = styled[element] as any;

  const Component = styledEl`
    letter-spacing: 0;
    font-style: normal;
    color: ${(props) => (props.white ? "#fff" : "#000")};
    text-align: ${(props) => (props.center ? "center" : "left")};
    ${(props) => (props.withLine ? verticalLine : "")}
    ${typographyConfig[variant as Variants]}
  `;

  return Component;
};
