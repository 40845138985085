import { ThemeProvider, Global } from "@emotion/react";

import { theme, global, reset } from "./theme";
import ErrorHandler from "./ErrorHandler";
import GeneralLayout from "./components/GeneralLayout";
import AnimatedRoutes from "./AnimatedRoutes";
import NewServiceWorkerVersion from "./components/NewServiceWorkerVersion";

import DeviceOrientationProvider from "./components/DeviceOrientationProvider";

const App = () => {

  window.oncontextmenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <NewServiceWorkerVersion />
      <Global styles={reset} />
      <Global styles={global} />
      <DeviceOrientationProvider>
        <GeneralLayout>
          <ErrorHandler>
            <AnimatedRoutes />
          </ErrorHandler>
        </GeneralLayout>
      </DeviceOrientationProvider>
    </ThemeProvider>
  );
};

export default App;
