import { FC, useEffect, useState, useRef } from "react";

import { SceneZoomProps, GetCoordinatesFunc } from "../../lib/scene";
import { SceneZoomRoot, ZoomedImage } from "./SceneZoom.style";
import { useScreenSize } from "../../hooks/useScreenSize";

const timeBetweenClicks = 800;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const getCoordinates: GetCoordinatesFunc = (
  point,
  { containerWidth, containerHeight, imageHeight, imageWidth },
  isFirstPoint
) => {
  let { zoomMagnification } = point;

  if (isFirstPoint) {
    zoomMagnification += 0.2;
  }

  let x = Math.round(
    containerWidth / 2 - zoomMagnification * point.horizontalCoordinate
  );
  let minX = Math.round(containerWidth - imageWidth * zoomMagnification);
  const maxX = 0;

  if (minX > maxX) {
    zoomMagnification = containerWidth / imageWidth;

    if (isFirstPoint) {
      zoomMagnification += 0.2;
    }

    x = Math.round(
      containerWidth / 2 - zoomMagnification * point.horizontalCoordinate
    );
    minX = Math.round(containerWidth - imageWidth * zoomMagnification);
  }

  if (x < minX) {
    x = minX;
  }

  if (x > maxX) {
    x = maxX;
  }

  let y = Math.round(
    containerHeight / 2 -
      zoomMagnification * (imageHeight - point.verticalCoordinate)
  );
  const minY = Math.round(containerHeight - zoomMagnification * imageHeight);
  const maxY = 0;

  if (y < minY) {
    y = minY;
  }

  if (y > maxY) {
    y = maxY;
  }

  return { x, y, zoomMagnification };
};

let isStopped = false;
let clickCount = 0;
let lastClickTime = 0;

const handleQuintupleClick = (cb) => {
  // get the current timestamp
  const currentTime = new Date().getTime();

  if (!clickCount || currentTime - lastClickTime > timeBetweenClicks) {
    // if there is no click or time more than time between clicks set first click
    clickCount = 1;
    lastClickTime = currentTime;

    return;
  }

  if (clickCount === 4) {
    // if this is {N}th click go to next page
    isStopped = true;
    clickCount = 0;
    lastClickTime = 0;

    cb();
  } else {
    // if this is not the {N}th click, increment the click counter and update the last click time
    clickCount = clickCount + 1;
    lastClickTime = currentTime;
  }
};

const SceneZoom: FC<SceneZoomProps> = ({ goForward, scene }) => {
  const { innerHeight } = useScreenSize();
  const [styles, setStyles] = useState<Record<string, string>>({});
  const containerEl = useRef<HTMLDivElement>(null);

  const runAnimation = async () => {
    const { animationInformation } = scene;
    const imageHeight = scene.picture.height;
    const imageWidth = scene.picture.width;
    const { offsetWidth, offsetHeight } = containerEl.current as HTMLDivElement;
    const sizes = {
      imageHeight,
      imageWidth,
      containerWidth: offsetWidth,
      containerHeight: offsetHeight,
    };

    const firstPosition = animationInformation[0];
    const { x, y, zoomMagnification } = getCoordinates(
      firstPosition,
      sizes,
      true
    );
    setStyles({
      transform: `translate(${x}px, ${y}px) scale(${zoomMagnification})`,
    });
    await delay(400);

    const {
      x: newX,
      y: newY,
      zoomMagnification: newZoomMagnification,
    } = getCoordinates(firstPosition, sizes);
    setStyles({
      transition: "transform 600ms",
      transform: `translate(${newX}px, ${newY}px) scale(${newZoomMagnification})`,
    });
    await delay(600); // wait till the end of scale animation

    for (
      let positionIndex = 0;
      positionIndex < animationInformation.length;
      positionIndex++
    ) {
      const currentPosition = animationInformation[positionIndex];
      const nextPosition = animationInformation[positionIndex + 1];
      await delay(currentPosition.zoomHoldMsec || 2000); // delay for looking at this position

      if (nextPosition) {
        if (isStopped) return;

        const { x, y, zoomMagnification } = getCoordinates(nextPosition, sizes);
        setStyles({
          transition: `transform ${currentPosition.movingSpeedMsec || 1000}ms`,
          transform: `translate(${x}px, ${y}px) scale(${zoomMagnification})`,
        });

        await delay(currentPosition.movingSpeedMsec || 1000); // delay for animation
      }
    }

    if (isStopped) return;

    goForward();
  };

  useEffect(() => {
    if (scene.picture) {
      isStopped = false;
      runAnimation();
    } else {
      goForward();
    }
  }, []);

  return (
    <SceneZoomRoot ref={containerEl} mobHeight={innerHeight}>
      <ZoomedImage
        style={styles}
        src={scene.picture && scene.picture.url}
        alt=""
        onClick={() => handleQuintupleClick(goForward)}
      />
    </SceneZoomRoot>
  );
};

export default SceneZoom;
